<div *ngIf="messageService.messages.length" class="fadeout">

	<br /><br />

	<div *ngFor="let msg of messageService.messages.reverse()" class="message">
		<span *ngFor="let part of msg.parts" [class]="part.style.toString()">
			{{ part.content }}
		</span>
	</div>

</div>